import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Divider from '../components/divider';
import Grid from '@material-ui/core/Grid';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../components/css/who-we-are.css';

const WhoWeArePage = ({data}) => {
  return (
    <Layout>
      <SEO title="Who We Are" description="Pauly Group is committed to the advancement of administrative excellence in community and technical colleges through diversity and gender equality." />
      <div className = "whoweare -container">
      <div className="whoweare -header">
        <h1>Who We Are</h1>
        <Divider alt="Decorative divider line" />
      </div>
      <div className="whoweare -experts">
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={12} md={6}>
            <h1>Experts in Community and Technical College Administrative Searches</h1>
            <p>At Pauly Group, we're proud to be one of the leading academic search consulting firms
            in the nation. Since our founding, we have focused solely on senior administrative
            searches for community and technical colleges. This singular focus allows us to
            consistently deliver success to our clients.</p>
            <p>We're experts in working with technical and community college Trustees,
            Presidents, HR Departments, Executive Administrators, community leaders
            and other stakeholders.</p>
            <p>Every member of our team is passionate for the unique mission of community and
            technical colleges. Each one is a former community or technical college
            professional and possesses at least a Master's degree from an accredited university.
            They have a proven commitment to diversity and gender equity, as well as
            ethical behavior. Simply put, they've been where you are, and have the skills
            to help you find the right leader to join your team.</p>
          </Grid>
          <Grid item sm={12} md={6}>
            <Image fluid={data.whoweareImage.childImageSharp.fluid} />
          </Grid>
        </Grid>
      </div>
      <div className="whoweare -history">
        <Grid container spacing={1} alignItems="center" className="whoweare -history-header">
          <Grid item sm={12} md={6}>
            <Image fluid={data.whoweareImage2.childImageSharp.fluid} />
          </Grid>
          <Grid item sm={12} md={6}>
            <h1>A History of Helping Community and Technical Colleges Find Ideal Senior Administrators</h1>
          </Grid>
        </Grid>
        <div className="whoweare -process-start">
          <p>As with many businesses, Pauly Group was started when our founder,
          Teri Pauly, discovered a need. But first, a little background.</p>
        </div>
        <div className="whoweare -process-text">
          <p>Teri had an Associate in Arts degree from Lincoln Land Community
          College in Springfield, Illinois, and a Bachelor of Arts degree in
          Management and a Master of Arts degree in Organizational Communication
          from the University of Illinois. She became a teacher, ultimately
          returning to LLCC as a tenure-track faculty member and then a becoming
          member of the adjunct staff at St. Louis Community College when her
          husband’s work took them to St Louis. She eventually became a partner
          in Cona/Pauly Consultants, a financial industry executive search firm
          in St. Louis.</p>
          <p>When her family was able to move back to Springfield, she was
          invited to serve on a Presidential Search Committee for Lincoln Land
          Community College. To her surprise, she discovered that there were no
          search firms specializing exclusively in community colleges. Following
          the search, and the unexpected death of her business partner, Teri
          decided to take the leap and establish the first search firm solely
          focused on community and technical colleges.</p>
          <p>Since it’s inception in 1990, Pauly Group, Inc. has been guided by
          a mission that defined our client colleges, as well as our firm:</p>
          <p>“The advancement of administrative excellence in community and
          technical colleges through diversity and gender equality.”</p>
          <p>Today, Pauly Group, Inc. is a strong and respected organization,
          working hard to assist community and technical colleges to identify,
          recruit, interview, reference and hire strong, diversified academic
          leaders for their schools.</p>
          <p>It is now under the leadership of President Angela Provart,
          with Les Pauly, as the company’s Board Chair.</p>
        </div>
      </div>
      <div className="whoweare -president">
        <div className="whoweare -president-header">
          <h1>A Message From the President</h1>
          <Divider /> <br />
          <Image fixed={data.presidentImage.childImageSharp.fixed} className="whoweare -president-image"/>
        </div>
        <p>Welcome and thank you for visiting our website! At Pauly Group,
        we have a passion for serving our client colleges. While we keep our
        work strictly confidential, we maintain the highest ethical standards
        when working with our client colleges, search committees, applicants
        and candidates. Pauly Group was founded in 1990 based on these principles
        of passion and ethics. They remain strong today.</p>
        <p>As president of Pauly Group, I personally lead every search.
        From the first call to the time the candidate is hired, I oversee and
        direct every aspect of our participation in your project. Each college
        is different and each search project is different. We expect and
        respect this. As a result, every detail matters in every search. We
        pride ourselves on excellent service to our client colleges and the
        ethical treatment of the parties involved.</p>
        <p>In today’s world, the correct use of technology is critical to a
        successful process. Pauly Group makes the search process more efficient
        and affordable by taking advantage of existing technology, as well as
        integrating new technologies as they become available. As always, you
        can count on Pauly Group to be at the front edge of this communication
        revolution.</p>
        <p>Please feel free to call me at any time. I’m happy to discuss with
        you our services and how we might help your college successfully
        navigate the search process.</p>
        <Image fixed={data.signatureImage.childImageSharp.fixed} />
      </div>
    </div>
  </Layout>
  );
}

export const query = graphql`
  query {
    whoweareImage: file(relativePath: {eq: "WhoWeAre_Image1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whoweareImage2: file(relativePath: {eq: "WhoWeAre_Image2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    presidentImage: file(relativePath: {eq: "President_Angela_Provart.jpg"}) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    signatureImage: file(relativePath: {eq: "President_Signature.png"}) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default WhoWeArePage
